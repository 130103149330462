/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-18 13:25:56
 * @Last Modified by:   Abhi Bhatt
 * @Last Modified time: 2020-07-11 18:09:33
 */
import axios from 'axios';
import { API_URL, EXTERNAL_API_URL } from '../../constants.js';

class CategoryService {
  async getCategory() {
    const url = `${API_URL}/get-category/`;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    };
    let formData = new FormData();
    return axios({
      url: url,
      method: 'get',
      crossDomain: true,
      data: formData,
      config: { headers: headers },
      //headers: {'Access-Control-Allow-Origin': '*'}
    })
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        return response;
      });
  }

  async getCategoryDetail(permalink) {
    const url = `${API_URL}/get-categories-detail/${permalink}/`;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    };
    let formData = new FormData();
    return axios({
      url: url,
      method: 'get',
      crossDomain: true,
      data: formData,
      config: { headers: headers },
      //headers: {'Access-Control-Allow-Origin': '*'}
    })
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        return response;
      });
  }

  /**
   * Get all available values in customFields from product table.
   * Categories: MGN-Frame-Size, MGN-HP, MGN-Product-Line, MGN-Voltage, MGN-Pole-RPM
   * @returns {Promise}
   */
  async getAvailableParams() {
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    };
    return axios({
      url: `${API_URL}/get-available-params/`,
      method: 'get',
      crossDomain: true,
      config: { headers: headers },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        return response;
      });
  }

  /**
   * Get all available values in customFields from product table according to current values.
   * Categories: MGN-Frame-Size, MGN-HP, MGN-Product-Line, MGN-Voltage, MGN-Pole-RPM
   * @returns {Promise}
   */
  async getAvailableParamsByCurrentParams(searchParams, customFields) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return axios({
      url: `${API_URL}/filter-available-params-by-current-params/`,
      method: 'post',
      headers: headers,
      data: { searchParams: [searchParams], customFields: customFields },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        return response;
      });
  }

  async getAvailablePartNumbers() {
    const headers = {
      'Content-Type': 'application/json',
    };
    return axios({
      url: `${API_URL}/get-available-part-numbers/`,
      method: 'get',
      crossDomain: true,
      config: { headers: headers },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        return response;
      });
  }

  async searchProductByPartNumber(partNumber) {
    const headers = {
      'Content-Type': 'application/json',
    };
    return axios({
      url: `${API_URL}/search-product-by-part-number/`,
      method: 'POST', 
      headers: headers,
      data: { partNumber: partNumber },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        return response;
      });
  }

  /**
   * @detail Search products by params
   * Get all available values in customFields from product table according to current search params.
   *
   * @param {*} searchParams
   * @returns
   */
  async searchProductsByParams(searchParams, customFields) {
    const headers = {
      'Content-Type': 'application/json',
    };
    return axios({
      url: `${API_URL}/search-products-by-params/`,
      method: 'post', 
      headers: headers,
      data: { searchParams: [searchParams], customFields: customFields },
    })
      .then(function (response) {
        return response;
      })
      .catch(function (response) {
        return response;
      });
  }
}

export default CategoryService;
