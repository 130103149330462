/*
* @Author: Abhi Bhatt
* @Date:   2020-07-14 12:49:01
* @Last Modified by:   abhi
* @Last Modified time: 2021-05-04 16:52:32
*/
import React from 'react';
import {Link,Redirect} from 'react-router-dom';
import $ from 'jquery';
import UserService from './../../services/UserService/UserService';

class Confirmation extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
            errors: {},
            success_redirect:0,
            confirmationToken:'',
            message:'',
            show_form:0,
            sendRequest:0
		}
		this.confirmationForm = this.confirmationForm.bind(this);
		this.successRedirect = this.successRedirect.bind(this);
		this.renderConfirmationForm = this.renderConfirmationForm.bind(this);
		this.renderConfirmationEvent = this.renderConfirmationEvent.bind(this);
		this.userService = new UserService();
	}

	componentDidMount() {
		this.setState({confirmationToken:this.props.match.params.confirmationToken})
		
	}

	confirmationForm(e) {
		this.setState({message:''})
		e.preventDefault();
		if(this.handleValidation()) {
			this.userService.confirmation(this.state.fields.email).then(response=>{
				if(response.status===200){
					if(response.data.response==='success'){
						$('#success_message').css('display','block');
            			$('#success_message').html(response.data.message);
						this.setState({success_redirect:1});
            			localStorage.setItem('show_message',1);
            			localStorage.setItem('success_message',response.data.message);
            			window.location.reload();
					}
				}
			});
		}

	}

	sendConfirmation() {
		if(this.state.sendRequest===0){
			this.userService.sendConfirmation(this.props.match.params.confirmationToken).then(response=>{
				this.setState({sendRequest:1});
				console.log(response);
				if(response.status===200){
					if(response.data.response==='success'){
						$('#success_message').css('display','block');
	        			$('#success_message').html(response.data.message);
						this.setState({success_redirect:1});
	        			localStorage.setItem('show_message',1);
	        			localStorage.setItem('success_message',response.data.message);
	        			window.location.reload();
					} else if(response.data.response==='fail')  {
						console.log(response.data.message);
						this.setState({show_form:1,message:response.data.message})
					}
				}
			});	
		}
		
	}

	successRedirect() {
    	if(this.state.success_redirect===1) {
    		return <Redirect to="/" />
    	}
    }

	handleValidation() {
    	let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if(!fields["email"]){
        	formIsValid = false;
        	errors["email"] = "Please enter email.";
        }
        this.setState({errors: errors});
       	return formIsValid;
    }

    handleChange(field, e){     
       // e.preventDefault();    
        let fields = this.state.fields;
        if(field.indexOf('data') !== -1)
        {
        	var newField = field.split(".");
        	if(newField[0] in fields)
        	{
	            if(newField[1] in fields[newField[0]])
	            {
	                if(newField[2] in fields[newField[0]][newField[1]])
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	                else
	                {
	                    fields[newField[0]][newField[1]][newField[2]] = '';
	                    fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                    fields[field] = e.target.value;
	                }
	            }   
	            else
	            {
	                fields[newField[0]][newField[1]] = {};  
	                fields[newField[0]][newField[1]][newField[2]] = '';
	                fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	                fields[field] = e.target.value;
	            }
	        }
	        else {
	            fields[newField[0]] = {};
	            fields[newField[0]][newField[1]] = {};
	            fields[newField[0]][newField[1]][newField[2]] = '';
	            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
	            fields[field] = e.target.value;
	        }
	    }
	    else
	    {
	    	fields[field] = e.target.value;
	    }
	    this.setState({fields});
	}

	renderConfirmationForm() {
		return(
			<React.Fragment>
				<form onSubmit={this.confirmationForm.bind(this)}>
					<span style={{color:'red'}}>{this.state.message}</span>
					<div className="row">
						<div className="col-md-11 col-sm-11 col-xl-9 col-lg-9">
							<div className="form-group">
								<input type="email" className="form-control" placeholder="E-mail" name="email" id="email" ref="email" onChange={this.handleChange.bind(this, "email")} defaultValue={this.state.fields["email"]}/>
								<span style={{color: "red"}}>{this.state.errors["email"]}</span>
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-primary">Resend confirmation instructions</button>
							</div>
							<div className="shared-links">
								<Link to={"/user/login"}>Sign in</Link>&nbsp;&nbsp;<Link to={"/user/login"}>Sign up</Link><br/>
								<Link to={"/user/forgot-password"}>Forgot your password?</Link>
							</div>
						</div>
					</div>		
				</form>
			</React.Fragment>	
		)
	}

	renderConfirmationEvent() {
		if(this.state.sendRequest===0){
			return(
				<React.Fragment>
					{this.sendConfirmation()}
				</React.Fragment>
			)	
		}
		
	}

	render() {
		return(
			<React.Fragment>
			{this.successRedirect()}
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
						<section className="new-area my-3">		
							<div className="row"> 
								<div className="col-md-12">
									<h5 className="pl-5" style={{fontSize:"1.2em"}}>Resend confirmation instructions</h5>
									<div className="row">
										<div className="col-md-6 offset-md-3">
											{(this.state.confirmationToken!='' && typeof this.state.confirmationToken!='undefined' && this.state.show_form===0) ? this.renderConfirmationEvent() : this.renderConfirmationForm() }
										</div>
									</div>	
								</div>
							</div>		
						</section>
					</div>	
				</main>
			</React.Fragment>
		);
	}
}

export default Confirmation;