import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CategoryService from './../../services/CategoryService/CategoryService';
import './SearchByPartNumber.css';

const PREVIEW_COUNT = 100;

const SearchByPartNumber = ({ sendProducts }) => {
  const categoryService = React.useMemo(() => new CategoryService(), []);
  const [partNumber, setPartNumber] = useState(null);
  const [allPartNumbers, setAllPartNumbers] = useState([]);
  const [filteredPartNumbers, setFilteredPartNumbers] = useState([]);

  useEffect(() => {
    getAvailablePartNumbers();
  }, []);

  const getAvailablePartNumbers = async () => {
    try {
      const response = await categoryService.getAvailablePartNumbers();
      if (response.status === 200) {
        const partNumbers = response.data.partNumbers.map((partNumber) => ({
          value: partNumber,
          label: partNumber,
        }));
        setAllPartNumbers(partNumbers);
        setFilteredPartNumbers(partNumbers.slice(0, PREVIEW_COUNT));
      } else {
        console.error('Error fetching category data:', response);
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  const handleInputChange = (inputValue) => {
    if (!inputValue) {
      // Reset to default if input is cleared
      setFilteredPartNumbers(allPartNumbers.slice(0, PREVIEW_COUNT));
    } else {
      // Filter the part numbers based on the input value
      const filtered = allPartNumbers.filter((partNumber) =>
        partNumber.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredPartNumbers(filtered.slice(0, PREVIEW_COUNT));
    }
  };

  const handlePartNumberChange = async (selectedOption) => {
    if (selectedOption) {
      setPartNumber(selectedOption);
      const product = await categoryService.searchProductByPartNumber(
        selectedOption.value
      );
      if (product.status === 200) {
        console.log('product.data.products[0]', product.data.products[0]);
        sendProducts([product.data.products[0]]);
      }
    } else {
      setPartNumber(null);
      sendProducts([]);
    }
  };

  return (
    <div className="search-by-part-number px-2 mb-4">
      <label className="font-weight-bold my-3">Search by Model Number</label>
      <div className="d-flex gap-1 w-100">
        <Select
          className="custom-select-container"
          classNamePrefix="custom-select"
          options={filteredPartNumbers}
          value={partNumber}
          onInputChange={handleInputChange}
          onChange={handlePartNumberChange}
          placeholder="Select a model number"
          isClearable
        />
      </div>
    </div>
  );
};

export default SearchByPartNumber;
