/*
* @Author: Abhi Bhatt
* @Date:   2020-06-26 14:54:11
* @Last Modified by:   abhishek714
* @Last Modified time: 2024-09-20 11:33:26
*/
import React from 'react';
import {Link} from 'react-router-dom';
import LocationService from './../../services/LocationService/LocationService';
import SideBar from '../../components/SideBar/SideBar';


const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

class Location extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location_data:[]
		}
		this.locationService = new LocationService();
		this.renderLoction = this.renderLoction.bind(this);
	}

	componentDidMount() {
		this.locationService.getLocations().then(request=>{
			if(request.status===200) {
				this.setState({location_data:request.data.result.inventory_places});
			}
		});

	}

	renderLoction() {
		let location_data = this.state.location_data;
		if(location_data && location_data.length>0) {
			return Object(location_data).map(function(value,key){
				return(
					<React.Fragment key={key}>
						<div className="col-md-6 col-sm-6 col-xl-3 col-lg-4">
							<Link to={'/location/'+value.fields.permalink}>{value.fields.name}</Link>
						</div>
					</React.Fragment>
				)
			});
		}

	}

	render() {
		let location_data = this.state.location_data;
		return(
			<React.Fragment>
				<main role="main" className="middle-content-area">
					<div className="container-fluid">
							<section className="product-area my-1">		
								<div className="row">
									<div className="col-md-12">
										<ol className="breadcrumb">
											<li className="breadcrumb-item"><a href={"/"}>Home</a><i className="fas fa-angle-right"></i></li>
											<li className="breadcrumb-item active">Locations</li>
										</ol>
									</div>
								</div>		
								<div className="row">
									<div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12">
										<SideBar/>
									</div>
									<div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
										<div className="loaction">
											<div className="row mb-1 mt-5">
												<div className="col-md-12">
													<div className="row">
														{this.renderLoction()}
													</div>	
												</div>	
											</div>	
									
										</div>
									</div>
								</div>
							</section>
					</div>	
				</main>
			</React.Fragment>
		)
	}
}

export default Location;