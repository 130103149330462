import React from 'react';
import { Link } from 'react-router-dom';
import ProductFBService from './../../services/ProductService/ProductFBService';
import PartService from './../../services/PartService/PartService';
import VoltageService from './../../services/VoltageService/VoltageService';
import SideBar from '../../components/SideBar/SideBar';
import ProductTable from '../../components/ProductTable/ProductTable.js';
import { API_URL } from '../../constants.js';
import GearSpinner from '../../components/GearSpinner/GearSpinner';
import SearchByPartNumber from '../../components/SearchByPartNumber/SearchByPartNumber';

class Motor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      motor_detail: { custom_fields: {} }, // Asegura un objeto para evitar errores undefined
      part_detail: { customFields: {} }, // Asegura que customFields esté inicializado
      voltageOptions: [],
      user_id: '',
      is_login: 0,
      media_url: '',
      baseModel: '',
      selectedVoltage: '',
      isLoading: true, // Estado para controlar la carga de datos
      filtered_products: [],
    };

    this.ProductFBService = new ProductFBService();
    this.VoltageService = new VoltageService();
    this.PartService = new PartService();
    // otros servicios
    this.loadDetails = this.loadDetails.bind(this);
    this.handleVoltageChange = this.handleVoltageChange.bind(this);
  }

  handleVoltageChange(event) {
    const selectedVoltage = event.target.value;
    this.setState({ selectedVoltage, isLoading: true }, () => {
      this.loadDetails(selectedVoltage);
    });
  }

  componentDidMount() {
    this.loadDetails();
  }

  setFilteredProducts = (products) => {
    this.setState({ filtered_products: products, product_detail: [] });
  };

  loadDetails() {
    const part_number = this.props.match.params.part_number;
    this.ProductFBService.getProductFBDetail(part_number)
      .then((response) => {
        if (response.status === 200 && response.data.product_detail) {
          const { product_detail, media_url } = response.data;
          const baseModel = product_detail.custom_fields['248']?.value;
          const voltageCode = product_detail.custom_fields['249']?.value;

          this.setState({
            motor_detail: product_detail,
            media_url: media_url,
            baseModel: baseModel,
            isLoading: false,
          });

          if (voltageCode) {
            this.VoltageService.getVoltageDetails(voltageCode).then(
              (voltageOptions) => {
                if (this.state.selectedVoltage === '') {
                  this.state.selectedVoltage =
                    voltageOptions.length > 0
                      ? voltageOptions[0]['Available Voltages']
                      : '';
                }

                this.setState({ voltageOptions }); // Actualiza el estado con las opciones de voltajes
              }
            );
          }
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.error('Error fetching motor details:', error);
        this.setState({ isLoading: false });
      });

    // Fetch part details
    this.PartService.getPartDetail(part_number)
      .then((response) => {
        console.log('Response received:', response);
        // Revisar si part_detail está directamente en response
        if (response && response.part_detail) {
          this.setState({ part_detail: response.part_detail });
        } else {
          console.error('Unexpected part detail response:', response);
        }
      })
      .catch((error) => {
        console.error('Error fetching part details:', error);
      });
  }

  renderMotorDetail() {
    const {
      motor_detail,
      part_detail,
      is_login,
      voltageOptions,
      selectedVoltage,
      baseModel,
    } = this.state;

    // Verificar si el motor no se encuentra
    if (
      !motor_detail ||
      !motor_detail.custom_fields ||
      Object.keys(motor_detail.custom_fields).length === 0
    ) {
      return (
        <div
          style={{
            textAlign: 'center',
            fontSize: '48px',
            color: 'red',
            marginTop: '20px',
          }}
        >
          MOTOR NOT FOUND
        </div>
      );
    }

    const downloadDatasheet = async (e) => {
      e.preventDefault(); // Prevenir el comportamiento por defecto del enlace
      const response = await fetch(
        `${API_URL}/get-datasheet/${motor_detail.part_number}/${selectedVoltage}`
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${baseModel}(${selectedVoltage
        .replace('-', '.')
        .replace('|', '.')}).pdf`;
      link.click();
      window.URL.revokeObjectURL(url);
    };

    return (
      <React.Fragment>
        <div className="products">
          <div className="row">
            <div className="col-md-8">
              <h3>
                <strong>{motor_detail.part_number}</strong>
              </h3>
            </div>
            <div className="col-md-4 text-right">
              {is_login === 1 ? (
                <p>
                  <Link to={`/motors/${motor_detail.part_number}/inventory`}>
                    Show Inventory
                  </Link>
                </p>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-2">
              <article className="motors">
                <table
                  className="table"
                  style={{
                    borderTop: '10px solid rgb(187, 93, 64)',
                    borderBottom: '10px solid rgb(187, 93, 64)',
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Motor Group</td>
                      <td>
                        {motor_detail.custom_fields['254']?.value || 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>Frame Size</td>
                      <td>
                        {motor_detail.custom_fields['253']?.value || 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>HP</td>
                      <td>{part_detail.customFields['76']?.value || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>RPM</td>
                      <td>{part_detail.customFields['105']?.value || 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>
                        For which operating voltage would you like the datapack
                        to be produced?
                      </td>
                      <td>
                        <select
                          value={selectedVoltage}
                          onChange={this.handleVoltageChange}
                        >
                          {voltageOptions.map((option, index) => (
                            <option
                              key={index}
                              value={option['Available Voltages']}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>List Price</td>
                      <td>
                        $
                        {motor_detail.price
                          ? parseFloat(motor_detail.price).toFixed(2)
                          : '0.00'}
                      </td>
                    </tr>
                    <tr>
                      <td>Multiplier Code</td>
                      <td>
                        {motor_detail.custom_fields['88']?.value || 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ul>
                  {motor_detail.drawing_pdf_id !== '' &&
                  motor_detail.drawing_pdf_id !== null ? (
                    <li>
                      <a
                        href={
                          this.state.media_url +
                          'drawing_pdf/' +
                          motor_detail.drawing_pdf_id
                        }
                      >
                        2D drawing
                      </a>
                    </li>
                  ) : null}
                  {motor_detail.url_3d !== '' &&
                  motor_detail.url_3d !== null ? (
                    <li>
                      <a href={motor_detail.url_3d}>3D drawing</a>
                    </li>
                  ) : null}
                  <li>
                    <a href="#" onClick={downloadDatasheet}>
                      Download Datapack
                    </a>
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;

    if (isLoading) {
      return <GearSpinner />;
    }

    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <section className="product-area my-1">
              <div className="row">
                <div className="col-md-12">
                  <ol className="breadcrumb">
                    {/* Renderizado de elementos de breadcrumb */}
                  </ol>
                </div>
              </div>
              <div className="row px-1">
                <div className="product-sidebar p-3 mt-2 mb-5 mx-3">
                  <SearchByPartNumber
                    className="mb-3"
                    sendProducts={this.setFilteredProducts}
                  />
                  <SideBar sendProducts={this.setFilteredProducts} />
                </div>
                <div className="product-content">
                  {this.state.filtered_products?.length === 0 ? (
                    this.renderMotorDetail()
                  ) : (
                    <ProductTable
                      products={this.state.filtered_products}
                      is_login={this.state.is_login}
                    />
                  )}
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Motor;
