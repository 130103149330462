/*
* @Author: Abhi Bhatt
* @Date:   2020-06-17 15:10:57
* @Last Modified by:   abhi714
* @Last Modified time: 2024-09-07 13:22:09
*/
import React from 'react';
//import ReactDOM from 'react-dom';
//import {Link} from 'react-router-dom';
import SliderService from './../../services/SliderService/SliderService';
import '../../js/custom.js';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });


const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: true,
    //autoplay: false,
    //navText: ["Prev", "Next"],
    //smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
            nav: false
        },
        600: {
        	items: 1,
        	nav: false
        },
        1000: {
        	items: 1,
        	nav: false,
        	loop: false
        }
    },
};

class Slider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			banner_images:[]
		};
		this.renderBannerImages = this.renderBannerImages.bind(this);
		this.renderSlider = this.renderSlider.bind(this);
		this.sliderService = new SliderService();

	}

	componentDidMount() {
		this.sliderService.getBannerImages().then(response=>{
			if(response.status===200) {
				this.setState({banner_images:response.data.result.banner_images});
			}
		});
	}

	componentDidMount() {
		this.sliderService.getBannerImages().then(response=>{
			if(response.status===200) {
				this.setState({banner_images:response.data.result.banner_images});
			}
		});
	}

	renderBannerImages(){
		var banner_images = this.state.banner_images;
		let media_url = this.state.media_url;
		if (banner_images && banner_images.length>0) {
		return Object(banner_images).map(function(value,key) {
			return(
			<React.Fragment key={key}>
				<div className="item" key={key}>
					<div className="row">
						<div className="col-md-4 col-sm-4 col-lg-3 col-xl-3 col-12 pr-0 d-none d-md-block">
							<div className="orange-bg px-3 py-4 h-100">
								<h2>Nema Premium</h2>
								<h1>Cast Iron</h1>
								<h2>Reporting for Severe Duty</h2>
							</div>
						</div>
						<div className="col-md-8 col-sm-8 col-lg-9 col-xl-9 col-12 pl-0">
							<img src={value.fields.image_file_name} alt="" className="d-block img-fluid mx-auto w-100" />
						</div>
						<div className="col-md-4 col-sm-4 col-lg-3 col-xl-3 col-12 pr-0 d-block d-md-none">
							<div className="orange-bg px-3 py-4 h-100">
								{renderHTML(value.fields.image_text)}
							</div>
						</div>
					</div>	
				</div>
			</React.Fragment>
			)
		});
		}
		else{
			return(
			<React.Fragment>
				<div className="item">
					<div className="row">
						<div className="col-md-4 col-sm-4 col-lg-3 col-xl-3 col-12 pr-0 d-none d-md-block">
							<div className="orange-bg px-3 py-4 h-100">
								<h2>Nema Premium</h2>
								<h1>Cast Iron</h1>
								<h2>Reporting for Severe Duty</h2>
							</div>
						</div>
						<div className="col-md-8 col-sm-8 col-lg-9 col-xl-9 col-12 pl-0">
							<img src="/images/logo-1440x393.png" alt="" className="d-block img-fluid mx-auto w-100" />
						</div>
						<div className="col-md-4 col-sm-4 col-lg-3 col-xl-3 col-12 pr-0 d-block d-md-none">
							<div className="orange-bg px-3 py-4 h-100">
								
							</div>
						</div>
					</div>	
				</div>
			</React.Fragment>
			)
		}
	}

	renderSlider() {
		return (
			<React.Fragment>
				<section className="slider-area">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<OwlCarousel className="owl-carousel owl-theme" {...options}>
									{this.renderBannerImages()}
								</OwlCarousel>	
							</div>	
							<div className="col-md-12">
								<div className="orange-title-bg">
									<h4>UPDATE: Techtop Announces Further Tariff Surcharge Reductions - August 26, 2019</h4>
								</div>
							</div>	
						</div>	
					</div>
				</section>
			</React.Fragment>
		)
	}

	render() {
		return (
			<React.Fragment>
				{this.renderSlider()}
			</React.Fragment>
		)
	}


}

export default Slider;