import React, { useEffect } from 'react';
import Raphael from 'raphael';
import usMap from './us-map-svg'; // Adjust the import according to your file structure
import marker1 from "../../images/map-marker.png";
import marker2 from "../../images/map-marker-2.png";

const USMap = () => {
    useEffect(() => {
        const R = Raphael("us_map", 1000, 900);
        const attr = {
            "fill": "#d3d3d3",
            "stroke": "#fff",
            "stroke-opacity": "1",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "4",
            "stroke-width": "0.75",
            "stroke-dasharray": "none"
        };

        // Draw Map
        for (const state in usMap) {
            const stateAttr = { ...attr };
            if (state === 'ok' || state === 'ky' || state === 'ga') {
                stateAttr.fill = '#fa4d09';
            } else if (state === 'or' || state === 'ca' || state === 'az' || state === 'tx' || state === 'il') {
                stateAttr.fill = '#fa4d09';
            }
            R.path(usMap[state]).attr(stateAttr);
        }

        // Coordinates for markers (x, y)
        const markers = {
            "Portland": { x: 85, y: 80 },
            "Fresno": { x: 80, y: 270 },
            "Georgia": { x: 710, y: 400 },
            "Reno": { x: 95, y: 160 },
            "Phoenix": { x: 200, y: 380 },
            "Odessa": { x: 360, y: 410 },
            "Houston": { x: 520, y: 460 },
            "Dallas": { x: 480, y: 430 },
            "Oklahoma": { x: 460, y: 360 },
            "Itasca": { x: 615, y: 215 },
            "Louisville": { x: 660, y: 290 }
        };

        const markerImages = {
            "Portland": marker1,
            "Oklahoma": marker2,
            "Georgia": marker2,
            "Louisville": marker2,
            "default": marker1
        };

        // Create clickable markers with an icon
        for (const city in markers) {
            const markerIcon = markerImages[city] || markerImages["default"];
            const marker = R.image(markerIcon, markers[city].x - 12, markers[city].y - 20, 24, 24);
            marker.attr({ cursor: 'pointer', title: city });

            (function (city) {
                marker.click(function () {
                    const targetElement = document.querySelector(`.${city}`);
                    const previousSelected = document.querySelectorAll('.map_location');
                    previousSelected.forEach(element => {
                        element.style.border = '';
                    });
                    if (targetElement) {
                        targetElement.style.border = '2px solid #fa4d09';
                        targetElement.scrollIntoView({ behavior: 'smooth' });
                    }

                    // Show city label
                    if (city === 'Oklahoma' || city === 'Georgia' || city === 'Louisville') {
                        const existingLabel = document.querySelector('.city-label');
                        if (existingLabel) {
                            existingLabel.remove();
                        }

                        const addressDetails = {
                            Oklahoma: `
                                <strong>OKLAHOMA CITY, OK</strong><br>
                                Techtop Industries Inc.<br>
                                8417 SW 23rd PL<br>
                                Oklahoma City, OK 73128<br>
                                Phone: 678-436-5515<br>
                                <a href="mailto:Okc-orders@techtopind.com">Okc-orders@techtopind.com</a>
                            `,
                            Georgia: `
                                <strong>GEORGIA (HEADQUARTERS)</strong><br>
                                Techtop Industries Inc.<br>
                                2815 Colonnades Court<br>
                                Norcross, GA 30071<br>
                                Phone: 678-436-5540<br>
                                Fax: 866-204-1498<br>
                                <a href="mailto:orders@techtopind.com">orders@techtopind.com</a>
                            `,
                            Louisville: `
                                <strong>LOUISVILLE, KY</strong><br>
                                Techtop Industries, Inc.<br>
                                260 Park Loop Rd<br>
                                Shepherdsville, KY 40165<br>
                                Phone: 678-248-5320<br>
                                <a href="mailto:lvl-orders@techtopind.com">lvl-orders@techtopind.com</a>
                            `,
                        };

                        const usMapDiv = document.getElementById('us_map');
                        const cityLabel = document.createElement('div');
                        cityLabel.className = 'city-label';
                        cityLabel.innerHTML = addressDetails[city];
                        cityLabel.style.position = 'absolute';
                        cityLabel.style.backgroundColor = '#125d67';
                        cityLabel.style.color = '#fff';
                        cityLabel.style.border = '1px solid #ccc';
                        cityLabel.style.padding = '10px';
                        cityLabel.style.borderRadius = '5px';
                        cityLabel.style.zIndex = '1000';
                        cityLabel.style.maxWidth = '300px';

                        const markerX = markers[city].x;
                        const markerY = markers[city].y;
                        cityLabel.style.left = `${markerX + 30}px`;
                        cityLabel.style.top = `${markerY - 10}px`;

                        usMapDiv.appendChild(cityLabel);
                        // Add global click handler to remove city label
                        const handleClickOutside = (event) => {
                            if (!cityLabel.contains(event.target) && !marker.node.contains(event.target)) {
                                cityLabel.remove(); // Remove the label
                                document.removeEventListener('click', handleClickOutside); // Cleanup event listener
                            }
                        };

                        // Delay attaching the event listener to avoid immediate triggering
                        setTimeout(() => {
                            document.addEventListener('click', handleClickOutside);
                        }, 0);

                    }
                });
            })(city);
        }
    }, []);

    return <div id="us_map" style={{ width: '100%', height: '550px', position: 'relative', textAlign: 'center' }}></div>;
};

export default USMap;