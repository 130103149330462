/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-18 13:25:29
 * @Last Modified by:   abhi
 * @Last Modified time: 2021-05-06 13:49:10
 */
import React from 'react';
//import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import CategoryService from './../../services/CategoryService/CategoryService';
import SideBar from '../../components/SideBar/SideBar';

const renderHTML = (rawHTML) =>
  React.createElement('div', { dangerouslySetInnerHTML: { __html: rawHTML } });

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category_data: [],
      //categories_detail:[]
    };
    this.categoryService = new CategoryService();
    this.renderCategoryDetail = this.renderCategoryDetail.bind(this);
  }

  componentDidMount() {
    const permalink = this.props.match.params.permalink;
    this.categoryService.getCategoryDetail(permalink).then((request) => {
      if (request.status === 200) {
        this.setState({
          categories_detail: request.data.result.categories_detail[0],
        });
        this.setState({
          category_data: request.data.result.categories_detail[0].category[0],
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const permalink = this.props.match.params.permalink;
    //if (prevState.categories_detail) { //Ensuring This is not the first call to the server
    if (permalink !== prevProps.match.params.permalink) {
      this.categoryService.getCategoryDetail(permalink).then((request) => {
        if (request.status === 200) {
          this.setState({
            categories_detail: request.data.result.categories_detail[0],
          });
          this.setState({
            category_data: request.data.result.categories_detail[0].category[0],
          });
        }
      });
    }
    //}
  }

  renderCategoryDetail() {
    let categories_detail = this.state.categories_detail;
    if (categories_detail && categories_detail.product.length > 0) {
      return Object(categories_detail.product).map(function (value, key) {
        return (
          <React.Fragment key={key}>
            <div className="categoriesBox">
              <div className="row mb-4">
                <div className="col-md-12">
                  <article className="product-details">
                    <Link to="">
                      <div className="row">
                        <div className="col-md-12">
                          <h3>{value.fields.name}</h3>
                        </div>
                      </div>
                    </Link>
                  </article>
                </div>
                <div className="col-md-12 mt-3">
                  <article className="product">
                    <div className="row">
                      <div className="col-md-9">
                        {renderHTML(value.fields.description)}
                      </div>
                      <div className="col-md-3">
                        <Link to={'/products/' + value.fields.permalink}>
                          <img
                            src={value.fields.photo_file_name}
                            alt=""
                            className="d-block img-fluid mx-auto"
                          />
                        </Link>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      });
    }
  }

  render() {
    let { category_data } = this.state;
    if (typeof category_data.fields !== 'undefined') {
      console.log(category_data.fields.name);
    }

    return (
      <React.Fragment>
        <main role="main" className="middle-content-area">
          <div className="container-fluid">
            <section className="product-area my-1">
              <div className="row">
                <div className="col-md-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href={'/'}>Home</a>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={'/products'}>Products</Link>
                      <i className="fas fa-angle-right"></i>
                    </li>
                    {typeof category_data.fields !== 'undefined' ? (
                      <li className="breadcrumb-item active">
                        {category_data.fields.name}
                      </li>
                    ) : (
                      ''
                    )}
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12 col-xl-2 col-lg-3 col-12">
                  <SideBar />
                </div>
                <div className="col-md-8 col-sm-12 col-xl-8 col-lg-8 col-12">
                  <div className="products">{this.renderCategoryDetail()}</div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Category;
